.row {
    flex-direction: row;
    width: 100%;
    border-bottom: 1px solid black;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: left;
    display: flex;
    min-height: 50px;
    align-items: center;
}
.titlerow {
    flex-direction: row;
    width: 100%;
    border-bottom: 1px solid black;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: left;
    display: flex;
    min-height: 75px;
    align-items: center;
}
.borderBox {
    border: 1px solid black;
}
.topBorder {
    border-top: 1px solid black;
}
.text {
    font-size: 16px;
    text-align: center;
}
.questionBox {
    width: 40%;
}
.bottomQuestionBox {
    width: 40%;
}
.emptyQuestionBox {
    width: 25%;
    border-bottom: 1px #fff;
}
.leftBorder {
    border-left: 1px solid black;
    min-height: 50px;
}
.leftBorderTitle {
    border-left: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 75px;
}
.answer {
    width: 15%;
}
.selectedAnswer {
    -moz-border-radius: 40px/40px;
    -webkit-border-radius: 40px 40px;
    border-radius: 40px/40px;
    border: solid 2px #01B050;
    width: 40px;
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.selectedAnswerLg {
    -moz-border-radius: 50px/50px;
    -webkit-border-radius: 50px 50px;
    border-radius: 50px/50px;
    border: solid 2px #01B050;
    width: 100px;
    min-height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.totalRowBox {
    width: 60%;
    min-height: 75px;
}