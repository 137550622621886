@import 'src/assets/css/variables';
@import 'src/assets/css/table-base';

.tableButtons {
  display: flex;
  flex-direction: row;
  padding: 18px;
  position: relative;
  .filtersRow {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-left: 30px;
  }
}

.btn {
  display: inline-block;
  height: 24px;
  border-radius: 3px;
  font-size: 12px;
  line-height: 14px;
  cursor: pointer;
  text-align: center;
  line-height: 24px;
}

.addBtn {
  float: right;
  width: 134px;
  background: $blue;
  color: $white;
}

.searchInput {
  width: 288px;
  height: 24px;
  border-radius: 3px;
  border: 1px solid $blue;
  font-size: 12px;
  line-height: 14px;
  padding-left: 30px;
  background: url('../../assets/icons/icn_search.svg') no-repeat left 0.7em center;

  &:focus {
    outline: none;
  }
}

.searchDelete {
  position: absolute;
  left: 288px;
  cursor: pointer;
}

.adminClientsTr {
  height: 87px;
  border-bottom: 0.3px solid #a3a6b3;
}

.tdInline {
  display: inline;
}

.noClientsTitle {
  font-size: 36px;
  font-weight: 500;
  line-height: 42px;
  margin-bottom: 18px;
  margin-top: 39px;
}

.noClientsSub {
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 64px;
}

.noClientsAddBtn {
  height: 48px;
  width: 147px;
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
  line-height: 48px;
  background-color: $blue;
  color: $white;
}

.nameContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: -4px;
  width: 100%;
}

.profilePicture {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  margin-right: 5px;
  object-fit: cover;
}

.picturePlaceholder {
  width: 24px;
  height: 24px;
  min-height: 24px;
  min-width: 24px;
  border-radius: 50%;
  background-color: $light_grey;
  margin-right: 5px;

  &__big {
    width: 41px;
    height: 41px;
    min-height: 24px;
    min-width: 24px;
    border-radius: 50%;
    background-color: $light_grey;
    margin-right: 17px;
  }

  &__loaded {
    width: 41px;
    height: 41px;
    max-height: 41px;
    max-width: 41px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 17px;
  }
}

.floatRight {
  float: right;
}

.closeBtnModal {
  margin: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 115px;
  text-decoration: none;
  border: none;
  outline: none;
  height: 24px;
  background: $blue;
  border-radius: 3px;
  font-size: 12px;
  line-height: 14px;
  color: $white;
  float: right;
  cursor: pointer;
}

.dataFullDisplay {
  white-space: normal;
}
.infoTooltip {
  //position: absolute;
  padding: 0px 10px 0px 17px;
  line-height: 18px;
  width: 250px;
  margin-top: -40px;
  margin-left: 5px;
}
.viviUnderline {
  text-decoration: underline red;
}

.viviSignup {
  text-decoration: underline blue;
}

.statusDescription {
  text-transform: none;
}

.statusHeader {
  text-transform: none;
}

h3 {
  margin-bottom: 0;
}

.status {
  margin-right: 10px;
}
