@import 'src/assets/css/variables';
@import 'src/assets/css/buttons';

.content {
  min-height: 350px;
  position: relative;
  display: flex;
  flex-direction: column;

  .header {
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    padding: 32px;
    border-bottom: 0.3px solid $medium_grey;
    text-transform: uppercase;
  }

  .body {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    p {
      font-size: 18px;
      font-weight: bold;
      color: $dark_grey;
    }
  }

  .footer {
    height: 113px;
    border-top: 0.3px solid $medium_grey;
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 30px;
    padding-right: 45px;

    p {
      padding: 0;
      margin: 0;
      width: 70%;
      font-size: 14px;
      line-height: 16px;
      color: $medium_grey;
    }

    .buttons {
      button:nth-child(1) {
        margin-right: 15px;
      }
      .btnPrimary {
        @include btnPrimary;
      }
    }
    .downloadLink {
      margin: 0px 50px;
    }
  }

  .loadingPosition {
    margin-bottom: 15px;
    margin-right: 45px;
  }

  .loadingRingFallback {
    margin-bottom: 15px;
    margin-right: 45px;
  }
}
