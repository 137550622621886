@import 'src/assets/css/variables';

.form {
  min-height: 430px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;

  .header {
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    padding: 32px;
    border-bottom: 0.3px solid $medium_grey;
    display: flex;
    justify-content: center;

    &__edit {
      text-align: center;
      padding: 32px;
      border-bottom: 0.3px solid $medium_grey;
      height: 110px;

      .header_title {
        font-size: 18px;
        font-weight: 600;
        line-height: 21px;
        display: block;
        margin-top: -3px;
      }
    }
  }

  .clientModalNav {
    float: left;
    margin-top: 26px;

    span {
      margin-right: 24px;
      color: $medium_grey;
      font-size: 14px;
      line-height: 16px;
      cursor: pointer;
    }

    .stepSelected {
      color: $blue;
      border-bottom: 2px solid $blue;
      padding-bottom: 10px;
    }
  }

  .body {
    margin: 32px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &__noMargin {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &__column {
      margin: 32px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      flex-direction: column;
    }
  }
}

.title {
  font-weight: 600;
  font-size: 16px;
}
.bottomButtons {
  display: flex;
  justify-content: space-between;
  margin: 32px 32px -5px 32px;
  position: relative;

  button {
    min-width: 115px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    outline: none;
    border-radius: 3px;
    font-size: 12px;
    line-height: 14px;
    cursor: pointer;
  }

  .submitBtn {
    background: $blue;
    border: none;
    color: $white;
    margin-left: 12px;
  }

  .closeBtn {
    background: $white;
    border: 2px solid $blue;
    color: $blue;
  }

  .cancelBtnRight {
    background: $white;
    border: 2px solid $blue;
    color: $blue;
    position: absolute;
    bottom: 0;
    right: 130px;
  }

  .rightButtons {
    display: flex;
    margin-bottom: 5px;

    .closeBtn {
      margin-right: 5px;
    }
  }
}

.genderRadioButtons {
  width: calc(100% - 34%);

  .label {
    font-size: 14px;
    line-height: 110%;
  }

  .radioButtons {
    margin-top: 23px;

    label {
      margin-right: 50px;
    }
  }
}

.bottomSection {
  display: flex;

  div {
    margin-right: 27px;
    margin-bottom: 0px;
  }
}

.errorText {
  color: $critical;
  font-size: 12px;
  line-height: 110%;

  &__checkbox {
    color: $critical;
    font-size: 12px;
    line-height: 110%;
    display: block;
    margin-top: 8px;
    opacity: 0;
  }

  &__checkbox__show {
    color: $critical;
    font-size: 12px;
    line-height: 110%;
    display: block;
    margin-top: 8px;
  }
}

.emergencySection {
  display: flex;

  div {
    margin-right: 27px;
  }
}

.emergencySection:nth-child(2) {
  margin-bottom: 75px;
}

.clinicInfoSection {
  display: flex;
  & > div {
    margin-right: 22px;
  }
}

.loadingState {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 100px;
  margin-bottom: 100px;
}

.field__big {
  width: 518px;
  display: flex;
  flex-direction: column;
  margin-bottom: 22px;
  position: relative;

  label {
    margin-bottom: 12px;
    color: $dark_grey;
  }

  input {
    height: 40px;
    min-height: 40px;
    background: $light_grey;
    border: 0;
    border-radius: 3px;
    padding-left: 10px;

    &:focus {
      outline: none;
    }
  }

  .inputDisabled {
    height: 40px;
    min-height: 40px;
    background: $light_grey;
    border: 0;
    border-radius: 3px;
    padding-left: 10px;
    color: $medium_grey;
    font-style: italic;
    cursor: not-allowed;
  }
}

.btnDisabled {
  border: none !important;
  cursor: default !important;
  background: $medium_grey !important;
  color: $white !important;
}

.removeModal {
  min-height: 690px;
  position: relative;

  .header {
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    padding: 32px;
    border-bottom: 0.3px solid $medium_grey;
  }

  .body {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 20px 10px;

    .eocContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0px 22px;
    }

    .confirmationText {
      color: $dark_grey;
      font-size: 24px;
      line-height: 110%;
      font-weight: 700;
      margin-bottom: 32px;
      min-height: 50px;
      text-align: center;
    }

    .outerSelect {
      position: relative;

      .customSelect {
        padding: 2px 12px;
        height: 29px;
        width: 324px;
        background: $white;
        color: $blue;
        border-radius: 3px;
        border: 1px solid $blue;
        font-size: 12px;
        background-image: url('../../assets/icons/arrow-down.svg');
        background-repeat: no-repeat, repeat;
        background-position: right 0.7em top 50%, 0 0;
        cursor: pointer;
      }

      .customSelectOptions {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 29px;
        width: 324px;
        border: 1px solid $medium_grey;
        border-radius: 3px;
        z-index: 99999;

        .customOption {
          height: 29px;
          padding: 2px 12px;
          font-size: 12px;
          background: $white;
          color: $dark_grey;

          &.selectable {
            &:hover {
              background: $blue;
              color: $white;
            }

            &:focus {
              background: $blue;
              color: $white;
            }
          }
        }
      }
    }

    .footNote {
      margin-bottom: 25px;
      height: 50px;
      font-size: 18px;
      line-height: 110%;
      color: $medium_grey;
      text-align: center;
    }

    .deviceParentContainer {
      display: flex;
      flex-direction: column;
      gap: 80px;

      .deviceContainer {
        position: relative;
        min-height: 132px;
      }

      .deviceName {
        display: flex;
        flex-direction: column;
        margin-bottom: 27px;
        max-height: 50px;

        .deviceRow {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          gap: 20px;
          color: $dark_grey;

          span:first-child {
            font-size: 14px;
            font-weight: 600;
            text-transform: uppercase;
          }
        }
      }

      .selectDisabled {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        background-color: rgba(255, 255, 255, 0.5);
        z-index: 999;
        opacity: 1;
        cursor: not-allowed;
      }
    }
  }

  .buttons {
    float: right;
    position: absolute;
    bottom: 32px;
    right: 32px;

    button {
      text-decoration: none;
      outline: none;
      border-radius: 3px;
      font-size: 12px;
      line-height: 14px;
      cursor: pointer;
      height: 24px;
      min-height: 24px;
    }

    .closeBtn {
      background: $white;
      border: 2px solid $blue;
      color: $blue;
      width: 115px;
      min-width: 115px;
    }

    .submitBtn {
      background: $blue;
      border: none;
      color: $white;
      margin-left: 12px;
      width: 132px;
      min-width: 132px;

      &:disabled {
        background: $medium_grey;
        cursor: not-allowed;
      }

      &:hover {
        background-color: #004c8a;
        color: #e0f0f6;
      }
    }
  }
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 30px;
}

.permissionsHeader {
  width: 100%;
  height: 20px;
  margin: 10px 10px;
  .navRight {
    padding: 0;
    text-align: center;
    li {
      display: flex;
      margin: 0;
    }
    .dropdownButton {
      cursor: pointer;
      margin: 0;
      border: 1px solid #417eb9;
      box-sizing: border-box;
      border-radius: 3px;
      padding: 5px 12px;
      align-items: center;
      color: #417eb9;
      .arrow {
        margin-left: 13px;
      }
    }
  }
}
