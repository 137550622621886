nav {
  width: 100%;
  height: 78px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 18px;

  .left {
    display: flex;
    align-items: center;

    figure {
      width: 190px;
      height: 55px;
      margin: 0;
      img {
        width: 220px;
        object-fit: contain;
      }
    }
    .orgName {
      margin-left: 88px;
      font-size: 20px;
      color: #417eb9;
      line-height: 23px;
    }
  }
  .right {
    display: flex;
    align-items: center;
    padding-right: 18px;
    .profileImage {
      border-radius: 50%;
      margin-right: 18px;
      margin-top: 24px;
      margin-bottom: 18px;
      cursor: pointer;
      outline: none;
    }
    .patientName {
      margin-right: 24px;
      font-size: 18px;
      line-height: 21px;
      color: #323335;
      margin-top: 29px;
      margin-bottom: 24px;
      cursor: pointer;
    }
    svg {
      margin-left: 18px;
      cursor: pointer;
    }
  }
}
.version {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  font-variant: small-caps;
  padding-left: 10px;
  /* Medium Grey */

  color: #a3a6b3;
}
