@import '../../../assets/css/variables';
@import 'src/assets/css/variables';
.questionAnswers {
  margin-top: 16px;
}
.selectedAnswer {
  background: #f5f6fa;
  border-radius: 10px;
  color: #355873;
  display: inline-block;
  padding: 5px 13px;
}

.questionCell {
  margin-top: 35px;
}
.mainQuestion {
  font-weight: bold;
}
.scoreLabel {
  font-weight: bold;
}
.subQuestionCell {
  margin-top: 16px;
}

.formLabel {
  label {
    margin-bottom: 12px;
    color: $dark_grey;
  }
}
.formRow {
  display: flex;
  justify-content: space-between;
}
.radioButtonsRow {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 12px;
  & > * {
    margin-right: 12px;
  }
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
}

.saveButton,
.cancelButton {
  display: flex;
  align-items: center;
  justify-content: center;
  float: right;
  padding: 5px 25px;
  margin-right: 18px;
  min-width: 115px;
  height: 24px;
  border: none;
  font-family: Roboto, serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  background: $blue;
  border-radius: 3px;
  color: $white;
  cursor: pointer;
}

.saveButton {
  display: flex;
  align-items: center;
  text-align: center;

  &:hover {
    background-color: #004c8a;
    color: #e0f0f6;
  }
}

.loadingModal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($color: #ffffff, $alpha: 0.7);
}

.scheduleModal {
  position: relative;
}
.body {
  padding: 32px;
}

.modalHeader {
  border-bottom: 0.3px solid #a3a6b3;

  h4 {
    margin: 0 auto;
    text-transform: uppercase;
    color: #323335;
    font-size: 18px;
    line-height: 12px;
    padding: 30px 32px;
    font-weight: 700;
  }
}

.errorText {
  color: $critical;
  font-size: 12px;
  line-height: 110%;

  &__checkbox {
    color: $critical;
    font-size: 12px;
    line-height: 110%;
    display: block;
    margin-top: 8px;
    opacity: 0;
  }

  &__checkbox__show {
    color: $critical;
    font-size: 12px;
    line-height: 110%;
    display: block;
    margin-top: 8px;
  }
}
