@import 'src/assets/css/variables';

.noDataContainer {
  height: calc(100vh - 168px);
  width: calc(100% - 36px);
  margin: 0px 18px;
  max-height: 564px;
  background: $white;
  display: flex;
  align-items: center;
  flex-direction: column;
  svg {
    margin-top: 140px;
  }
}

.mapContainer {
  height: 700px;
  min-height: 500px;
}

.box {
  position: absolute;
  width: 360px;
  height: 210px;
  left: 25px;
  top: -122px;
  background: #ffff;
  display: flex;
  padding: 30px 27px 30px 41px;
  flex-direction: column;
  justify-content: flex-start;
  clip-path: polygon(3% 0, 100% 0%, 100% 100%, 3% 100%, 0% 50%);
  z-index: 9999;
  .title {
    font-size: 17px;
    line-height: 17px;
    align-items: center;
    text-transform: uppercase;
    color: #ed6f6a;
    margin: 0 0 21px;
  }
  p {
    span {
      font-weight: 600;
    }
    color: #323335;
    display: block;
    margin: 0;
    padding-bottom: 15px;
    font-size: 12px;
    line-height: 15px;
  }
}
