@import 'src/assets/css/variables';

.container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  width: 100%;
  margin-bottom: 30px;
  padding: 32px 32px 24px 32px;

  .horizontalLine {
    position: absolute;
    top: 50%;
    left: 0;
    border: 1px solid #d8d8d8;
    width: calc(100% - 50px);
    margin-left: 20px;
  }
  .verticalLine {
    position: absolute;
    left: 50%;
    top: 0;
    border: 1px solid #d8d8d8;
    height: calc(100% - 55px);
    margin-top: 35px;
  }
}

.statsCard {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-bottom: 25px;
  min-height: 330px;

  .title {
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    text-align: center;
    margin-top: 20px;
  }
  .tableBody {
    max-height: 220px;
    overflow: scroll;
  }
  .dataRow {
    margin-top: 32px;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    justify-content: flex-start;
    gap: 40px;
    color: #323335;
    > span:first-child,
    strong:first-child {
      width: 200px;
    }
    > span:nth-child(2) {
      width: 90px;
    }

    &.clientAssignedTable {
      > span:first-child,
      strong:first-child {
        width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        justify-content: flex-start;
      }
      > span,
      strong {
        width: 80px;
        display: flex;
        justify-content: center;
      }
    }
  }

  .devicesTable {
    ul {
      margin: 32px 0;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      flex-direction: row;
    }

    ul:nth-child(1) {
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      color: #323335;
    }

    li {
      margin-bottom: 10px;
      min-width: 115px;
      padding: 0 20px;
      .deviceImage {
        width: 44px;
        height: 44px;
      }
    }

    li:nth-child(1) {
      text-align: left;
    }

    .tableValue {
      font-size: 18px;
      font-weight: 700;

      .actionIcon {
        margin: 0px 5px;
        cursor: pointer;
      }
    }
  }

  .clientStatBottom {
    margin-top: 32px;
    font-size: 14px;
    line-height: 16px;
    color: $medium_grey;

    span {
      margin-right: 30px;
    }
  }
}
