.range {
  display: inline-block;
  font-size: 12px;
  color: #444444;
  .rangeValue {
    display: inline-block;
    width: 40px;
    text-align: end;
    font-weight: 700;
  }
  .rangeDescription {
    padding-left: 18px;
  }
}
