@import '../../../../assets/css/variables';

.meetingModal {
  margin: 0 0 32px;

  .modalHeader {
    border-bottom: 0.3px solid #a3a6b3;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    height: 78px;
    padding: 30px 32px;

    > div:first-child {
      color: $dark_grey;
      justify-content: flex-start;
      text-transform: uppercase;
      font-size: 18px;
      line-height: 12px;
      font-weight: bold;
    }

    div:last-child {
      align-self: flex-end;
      line-height: 12px;
    }
    .pendingStatus {
      font-weight: 600;
      color: $dark_grey;
    }
  }

  .meetingDetail {
    height: 60px;
    border-bottom: 0.3px solid $medium_grey;
    width: 100%;
    margin: 0 auto;
    padding: 18px 18px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .box {
      font-family: 'Roboto', serif;
      font-size: 14px;
      color: $dark_grey;
      padding: 0 10px;

      span {
        font-weight: bold;
      }
    }
  }

  .mainSection {
    margin: 32px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;

    .mainText {
      display: flex;
      flex-direction: row;
      align-content: flex-start;
      color: #323335;
      font-size: 16px;
      font-weight: 600;
    }

    .secondaryText {
      font-size: 16px;
      line-height: 110.69%;
      color: $medium_grey;
      margin: 18px 0 0;
      align-self: start;
      flex-shrink: 1;
    }

    .applyChangesSection {
      display: flex;
      flex-direction: column;
      margin-top: 50px;
    }

    .label {
      font-size: 14px;
      line-height: 110%;
      font-weight: 600;
    }

    .radioButtons {
      margin-top: 25px;
      display: flex;
      flex-direction: column;

      label {
        margin-bottom: 25px;
      }
    }

    .errorCheckbox {
      color: $critical;
      font-size: 12px;
      line-height: 110%;
      display: block;
      margin-top: 8px;
    }
  }

  .bottomButtons {
    margin: 0 32px 0 32px;

    button {
      min-width: 115px;
      height: 24px;
      border-radius: 3px;
      font-size: 12px;
      cursor: pointer;
    }

    .submitBtn {
      background: $blue;
      border: none;
      color: $white;
      margin-left: 12px;

      &:hover {
        background-color: #004c8a;
        color: #e0f0f6;
      }
    }

    .closeBtn {
      margin-left: auto;
      background: $white;
      border: 2px solid $blue;
      color: $blue;
      margin-right: 5px;
    }

    .rightButtons {
      display: flex;
    }
  }
}
