@import '../../../../assets/css/variables';
.locationModal {
  margin: 0;

  .modalHeader {
    border-bottom: 0.3px solid #a3a6b3;

    h4 {
      margin: 0 auto;
      text-transform: uppercase;
      color: $dark_grey;
      font-size: 18px;
      line-height: 12px;
      padding: 30px 32px;
      font-weight: bold;
    }
  }

  .meetingDetail {
    height: 60px;
    border-bottom: 0.3px solid $medium_grey;
    width: 100%;
    margin: 0 auto;
    padding: 18px 18px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .box {
      font-size: 14px;
      color: $dark_grey;
      padding: 0 10px;

      span {
        font-weight: bold;
      }
      .upcomingStatus {
        font-size: 12px;
        color: $medium_grey;
      }
    }
  }

  .mapContainer {
    height: 600px;
    min-height: 400px;
  }
}
