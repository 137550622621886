@import 'src/assets/css/variables';
@import 'src/assets/css/table-base';

.geofenceHeader {
  width: 100%;
  height: 60px;
  nav {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
    width: 100%;
    float: left;
    height: 60px;
    .navLeft {
      padding: 0;
      text-align: center;
      li {
        float: left;
        font-family: Roboto, sans-serif;
        font-weight: normal;
        font-size: 12px;
        padding: 5px 10px;
        line-height: 15px;
        margin-right: 14px;
        height: 24px;
        list-style-type: none;
        cursor: pointer;
        &.active {
          background: #a3a6b3;
          border-radius: 3px;
          color: #fff;
        }
        &.inactive {
          color: #a3a6b3;
          &:hover {
            background: #a3a6b3;
            border-radius: 3px;
            color: #fff;
          }
        }
      }
    }

    .navRight {
      padding: 0;
      text-align: center;
      .options {
        margin-left: 18px;
        float: right;
        font-size: 12px;
        a {
          padding: 5px 15px;
          min-width: 150px;
          text-decoration: none;
          border: none;
          outline: none;
          background: #417eb9;
          border-radius: 3px;
          color: $white;
          cursor: pointer;
          &:hover {
            background-color: #004c8a;
            color: #e0f0f6;
          }
        }
        select {
          cursor: pointer;
          margin: 0;
          border: 1px solid #417eb9;
          box-sizing: border-box;
          border-radius: 3px;
          padding: 3px 22px 3px 12px;
          align-items: center;
          color: #417eb9;
          background: none;
        }
      }

      .dropdownButton {
        cursor: pointer;
        margin: 0;
        border: 1px solid #417eb9;
        box-sizing: border-box;
        border-radius: 3px;
        padding: 5px 12px;
        align-items: center;
        color: #417eb9;
        .arrow {
          margin-left: 13px;
        }
      }
      .dropdownContainer {
      }
    }
  }
}

.filterDatepicker {
  position: absolute;
  top: 2px;
  right: 0;
  box-shadow: 0 2px 6px #e5e5e5;
  border-radius: 3px;
  .rdrDefinedRangesWrapper div {
    background: #2f2a30 !important;
  }
}
