@import 'src/assets/css/variables.scss';
// forgot password modal
.forgotPasswordModal {
  margin: 0 auto;
  .modal_header {
    border-bottom: 0.3px solid #a3a6b3;
  }
}

.forgotPasswordModal h4 {
  margin: 0 auto;
  text-transform: uppercase;
  color: $dark_grey;
  font-size: 18px;
  line-height: 12px;
  padding: 33px 31px;
  font-weight: bold;
}

.forgotPasswordModal p {
  text-align: center;
  margin: 54px auto 35px;
  font-size: 16px;
  line-height: 110.69%;
  align-items: center;
  color: $dark_grey;
  font-weight: bold;
}

.forgotPasswordModal form {
  width: 337px;
  margin: 0 auto 100px;
  @media (max-width: 600px) {
    width: 100%;
  }
  label {
    padding: 15px 0 18px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #323335;

    .emailLabel {
      display: block;
      margin-bottom: 18px;
      cursor: pointer;
    }

    .emailInput {
      width: 100%;
      background: #f5f6fa;
      border-radius: 3px;
      font-family: Roboto, serif;
      font-size: 14px;
      line-height: 16px;
      color: #a3a6b3;
      border: 0;
      outline: none;
      box-shadow: none;
      padding: 15px 0px 15px 10px;
      height: 40px;
      &.success {
        border: 1px solid $stable;
      }
      &.messageError {
        border: 1px solid $red;
      }
    }
  }
  .tooltip {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    margin-top: 10px;
    &.success {
      color: $stable;
    }
    &.messageError {
      color: $red;
    }
  }
}

.forgotPasswordModal button {
  margin: 0 auto;
  float: right;
}

.forgotPasswordModal .rowButtons {
  width: 100%;
  float: right;
  padding-bottom: 29px;
  padding-right: 32px;
  display: inline-block;
  .saveButton {
    padding: 5px 25px;
    margin-right: 18px;
    min-width: 115px;
    width: auto;
    height: 24px;
    display: inline-block;
    border: none;
    outline: none;
    font-family: Roboto, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    background: #417eb9;
    border-radius: 3px;
    color: $white;
    cursor: pointer;
    &:hover {
      background-color: #004c8a;
      color: #e0f0f6;
    }
    &:disabled {
      background-color: $medium_grey;
      color: $white;
      cursor: not-allowed;
    }
  }
  .cancelButton {
    margin-right: 18px;
    padding: 5px 25px;
    color: #417eb9;
    font-family: Roboto, sans-serif;
    border-radius: 3px;
    font-size: 12px;
    line-height: 0;
    border: 2px solid #417eb9;
    width: auto;
    background: #ffffff;
    height: 24px;
    min-width: 115px;
    display: inline-block;
    cursor: pointer;
  }
}
