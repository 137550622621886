@import 'variables.scss';

.btn {
  border-radius: 3px;
  width: 115px;
  height: 24px;
  border: none;
  outline: none;
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  cursor: pointer;
  &.primary {
    background-color: $blue;
    color: $white;
    &:hover {
      background-color: #004c8a;
      color: #e0f0f6;
    }
  }
  &.disabled {
    background-color: $medium_grey;
    color: $white;
  }
  &.secondary {
    background: $white;
    color: $blue;
    border: 2px solid $blue;
    box-sizing: border-box;
    &.disabled {
      background-color: $medium_grey;
      color: $medium_grey;
      pointer-events: none;
    }
  }
}

@mixin btnPrimary {
  padding: 5px 15px;
  min-width: 150px;
  text-decoration: none;
  border: none;
  outline: none;
  background: $blue;
  border-radius: 3px;
  color: $white;
  cursor: pointer;
  &:hover {
    background-color: #004c8a;
    color: #e0f0f6;
  }
}
