.chartContainer {
  z-index: 9999;
}

.areaContainer {
  z-index: 999;
}

.chartHeader {
  //display: flex;
  align-items: center;
}

.chartTitle {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #323335;
  margin-left: 70px;
  margin-right: 5px;
}

.chartToolTipReverse {
  background-color: #fff;
  height: 62.5px;
  width: 150px;
  padding: 5px;
  border-top: 1px solid #a3a6b3;
  border-left: 1px solid #a3a6b3;
  border-bottom: 1px solid #a3a6b3;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 21px;
  margin-top: -32px;
  z-index: 9999;
}

.chartToolTipReverse:before {
  left: calc(100% - 21px);
  top: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(0, 128, 0, 0);
  border-left-color: #a3a6b3;
  border-width: 31.25px;
  margin-top: -47.25px;
  z-index: 9999;
}

.chartToolTipReverse:after {
  left: calc(100% - 21px);
  top: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(0, 128, 0, 0);
  border-left-color: #fff;
  border-width: 30.25px;
  margin-top: -46.25px;
  z-index: 9999;
}

.chartToolTip {
  background-color: #fff;
  height: 62.5px;
  width: 150px;
  padding: 5px;
  border-top: 1px solid #a3a6b3;
  border-right: 1px solid #a3a6b3;
  border-bottom: 1px solid #a3a6b3;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 25px;
  margin-top: -32px;
  z-index: 9999;
}

.chartToolTip:before {
  right: calc(100% - 25px);
  top: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(0, 128, 0, 0);
  border-right-color: #a3a6b3;
  border-width: 31.25px;
  margin-top: -47.25px;
  z-index: 9999;
}

.chartToolTip:after {
  right: calc(100% - 25px);
  top: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(0, 128, 0, 0);
  border-right-color: #fff;
  border-width: 30.25px;
  margin-top: -46.25px;
  z-index: 9999;
}

.chartBigToolTip {
  background-color: #fff;
  padding: 10px;
  border: 1px solid #a3a6b3;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 110px;
  margin-top: -27px;
  z-index: 9999;
  min-height: 180px;

  &.sleepBigTooltip {
    &::before {
      border-width: 113.25px;
      margin-top: -127.25px;
      right: calc(100% - 110px);
    }

    &::after {
      border-width: 112.25px;
      margin-top: -126.25px;
      right: calc(100% - 110px);
    }
  }
  &.adherence {
    margin-top: -130px;

    &::before {
      margin-top: -155px;
    }

    &::after {
      margin-top: -153px;
    }
  }

  &::before,
  &::after {
    right: calc(100% - 90px);
    border: solid transparent;
    top: 50%;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(0, 128, 0, 0);
    z-index: 9999;
  }

  &::before {
    border-right-color: #a3a6b3;
    border-width: 89.25px;
    margin-top: -127.25px;
  }

  &::after {
    border-right-color: #fff;
    border-width: 87.25px;
    margin-top: -125.25px;
  }

  &.reverse {
    margin-right: 78px;
    margin-left: unset;

    &::before,
    &::after {
      left: calc(100% - 78px);
      right: unset;
    }

    &::before {
      border-left-color: #a3a6b3;
      border-right-color: transparent;
    }

    &::after {
      border-left-color: #fff;
      border-right-color: transparent;
    }
  }
}

.chartToolTipTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #323335;
  border: 1px solid #ffffff;
}

.chartTooltipContent {
  span {
    font-size: 12px;
    line-height: 14px;
    color: #323335;
    font-weight: normal;
    font-style: normal;
  }
}

.chartTooltipContentDates {
  display: flex;
  flex-direction: column;

  span {
    font-size: 12px;
    line-height: 14px;
    color: #323335;
    font-weight: normal;
    font-style: normal;
    margin: 3px 0px;
  }
}

.chartTooltipComponentsContainer {
  font-style: normal;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #323335;

  .warning {
    color: #ed6f6a;
    font-weight: bold;
  }
}

.hasDates {
  width: 200px;
}

.infoTooltip {
  width: 509px;
  max-height: 550px;
  font-size: 12px;
  line-height: 14px;
  background: #f5f6fa;
  color: #444444;
  padding: 30px 16px 20px 30px;
  top: -140px;
  z-index: 99999;

  &.activity {
    top: -112px;
    width: 579px;
  }

  &.engagement {
    top: 0;
    padding: 20px 16px 5px 20px;
  }

  &.mood {
    top: -137px;
    width: 750px;
    padding: 16px 16px 10px 20px;

    hr {
      margin: 0;
      width: 440px;
    }

    .parentWrapper {
      margin-bottom: 6px;

      .scoreRow {
        display: flex;
        font-size: 11px;

        .score {
          width: 271px;
          display: flex;
          flex-direction: row;
          margin-right: 10px;

          .scoreValue {
            font-size: 11px;
            line-height: 13px;
            display: flex;
            justify-content: flex-end;
            color: #323335;
          }
        }
      }
    }
  }

  &.sleep {
    left: 30px;
    top: 10px;
  }

  &.events {
    top: -150px;
  }

  &.vivihealth {
    top: -254px;

    .list {
      width: calc(100% - 55px);
    }

    .scoreDescriptionContainer {
      display: flex;
      flex-direction: row;
    }

    .scoreContainer {
      margin-right: 35px;
    }

    .statusContainer {
      display: flex;
      flex-direction: column;
      align-items: center;

      em {
        text-align: center;
        line-height: 12px;
        font-size: 9px;
      }

      p {
        margin: 4px 0 10px;
      }

      .statusLabel {
        &.critical {
          background: linear-gradient(90deg, rgb(209, 129, 149) 0%, rgb(234, 105, 105) 100%);
        }

        &.atRisk {
          background: linear-gradient(90deg, rgb(227, 146, 83) 0%, rgb(240, 191, 85) 100%);
        }

        &.stable {
          background: linear-gradient(270deg, rgb(107, 227, 186) 0%, rgb(92, 197, 226) 70%);
        }

        text-align: center;
        width: 100px;
        margin: 0 0 5px;
        line-height: 16px;

        strong {
          display: inline-block;
          font-size: 12px;
          color: rgb(68, 68, 68);
        }
      }
    }
  }

  &.adherence {
    top: -228px;
    width: 610px;
    padding: 16px 16px 10px 20px;
  }

  h3 {
    margin: 0 0 13px;
    font-size: 18px;
    font-weight: 700;
    color: #323335;
    text-transform: uppercase;
  }

  ul {
    margin-left: 32px;

    li {
      margin: 0 0 3px;
    }
  }

  figure {
    width: 14px;
    margin-right: 8px;
    vertical-align: middle;
  }

  em {
    font-size: 11px;
  }

  h5 {
    margin: 6px 0 6px;
    font-weight: 500;
  }

  h6 {
    margin: 0 0 5px;
    font-weight: 500;
  }
}

.vitalsTooltip {
  width: 509px;
  max-height: 140px;
  padding: 10px 20px 10px 25px;
  font-size: 12px;
  line-height: 14px;
  background: #f5f6fa;
  color: #444444;

  &.hrvTooltip {
    top: -46px;
  }

  &.hrTooltip {
    top: 9px;
    left: 25px;
  }
}

.sleepTooltip {
  .sleepTitle {
    font-size: 16px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    .progressBarContainer {
      margin-left: 135px;
      .progressBar {
        border-radius: 100px;
        margin-bottom: 4px;
        height: 6px;
        position: relative;
        .progressBarGrey {
          background-color: #f5f6fa;
          width: 100%;
          height: 100%;
          border-radius: 100px;
          .progressBarInside {
            border-radius: 100px;
            height: 100%;
            position: absolute;
            top: 0;
          }
        }
      }
    }
  }
  .sleepChartContainer {
    display: flex;
    flex-direction: row;
    .legendContainer {
      margin-left: 10px;

      .legendItem {
        display: flex;
        margin-bottom: 10px;

        .color {
          width: 30px;
          height: 30px;
          border-radius: 10px;
          margin-right: 4px;
        }

        .label {
          display: flex;
          flex-direction: column;
          line-height: 16px;

          .title {
            font-weight: 600;
            font-size: 14px;
            text-transform: capitalize;
          }
        }
      }
    }
  }
}
.chartContainer {
  margin-top: 20px;
}