@import 'src/assets/css/variables';

.helper {
  position: relative;
  display: inline;
  padding-left: 1px;

  figure {
    display: inline-block;
    margin: 0;
    margin-top: 0px;
    svg {
      cursor: pointer;
    }
  }
}

.tooltip {
  position: absolute;
  left: 31px;
  top: -24px;
  padding: 0px 10px 0px 17px;
  z-index: 999;
  background-color: $white;
  filter: drop-shadow(0 5px 10px $medium_grey);

  .tooltipBottom {
    position: absolute;
    bottom: -25px;
    left: 0;
    width: 100%;
    height: 28px;
    clip-path: polygon(30% 100%, 0 0, 100% 0);
    background-color: $white;
  }

  .tooltipLeft {
    position: absolute;
    bottom: 0px;
    left: -14px;
    width: 30px;
    height: 100%;
    clip-path: polygon(50% 0%, 0 50%, 50% 100%);
    background-color: $white;
  }

  .tooltipUp {
    position: absolute;
    bottom: 0px;
    left: -10px;
    width: 20px;
    height: 100%;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 13% 100%, 13% 8%);
    background-color: $light_grey;
  }
  .tooltipRight {
    position: absolute;
    bottom: 0;
    right: -30px;
    width: 30px;
    height: 100%;
    -webkit-clip-path: polygon(0% 0%, 0% 100%, 100% 37%);
    clip-path: polygon(0% 0%, 0% 100%, 100% 37%);
  }
  .tooltipBottomLeft {
    position: absolute;
    top: 75%;
    left: -90px;
    width: 100px;
    height: 25%;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 13% 100%, 13% 8%);
    clip-path: polygon(100% 100%, 100% 0%, 150% 65%, 50% 20%, 100% 0%);
  }
}
