@import 'src/assets/css/variables';
@import 'src/assets/css/table-base';

.navHeader {
  padding: 18px;
  position: relative;
  display: flex;
  flex-direction: row;
  .filtersRow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    margin-left: 30px;
  }
}

.alumniTr {
  height: 87px;
  border-bottom: 0.3px solid #a3a6b3;
}

.tdInline {
  display: inline;
}

.nameContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: -4px;
  width: 100%;
}

.profilePicture {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  margin-right: 5px;
  object-fit: cover;
}

.picturePlaceholder {
  width: 24px;
  height: 24px;
  min-height: 24px;
  min-width: 24px;
  border-radius: 50%;
  background-color: $light_grey;
  margin-right: 5px;
}

.admissionDateContainer {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  line-height: 16px;
}

.dataFullDisplay {
  white-space: normal;
}

.expandAction {
  margin-top: 20px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  color: #417eb9;
  font-size: 25px;

  &.expanded {
    font-size: 45px;
  }
}

.subRowTr {
  padding-left: 200px;
  border-bottom: 0.3px solid #a3a6b3;
}
.subRow {
  display: flex;
  flex-direction: column;
  width: 100%;

  .header {
    display: flex;
    flex-direction: row;
    padding: 10px 18px;
    .columnHeader {
      font-size: 14px;
      color: #323335;
      min-width: 260px;
      justify-content: flex-start;
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  .eocRow {
    display: flex;
    flex-direction: row;
    background: #f5f6fa;
    margin-bottom: 10px;
    margin-right: 13px;
    height: 64px;
    .cell {
      display: inline;
      width: 260px;
      line-height: 18px;
      padding: 18px 21px 18px 21px;
    }
  }
}
