@import '../../../../assets/css/variables';
.bacTestRequestModal {
  margin: 0 auto;

  .modalHeader {
    border-bottom: 0.3px solid #a3a6b3;

    h4 {
      margin: 0 auto;
      text-transform: uppercase;
      color: $dark_grey;
      font-size: 18px;
      line-height: 12px;
      padding: 30px 32px;
      font-weight: bold;
    }
  }
  .bacTestRequestContainer {
    display: flex;
    flex-direction: column;
    .bacImage {
      display: flex;
      justify-content: center;
      align-content: center;
      margin: 50px 0;
    }
    .mainText {
      display: flex;
      flex-direction: column;
      p {
        font-weight: 600;
        font-size: 36px;
        line-height: 42px;
        text-align: center;
        color: #323335;
        display: flex;
        justify-content: center;
        align-content: center;
        width: 750px;
        margin: 0 auto;
      }
      h3 {
        margin: 64px 0;
        font-weight: normal;
        font-size: 18px;
        text-align: center;
        color: #323335;
      }
    }
  }
  .rowButtons {
    float: right;
    margin: 32px 0;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      float: right;
      padding: 5px 25px;
      margin-right: 18px;
      min-width: 115px;
      height: 24px;
      border: none;
      font-family: Roboto, serif;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      background: $blue;
      border-radius: 3px;
      color: $white;
      cursor: pointer;
    }

    .saveButton {
      display: flex;
      align-items: center;
      text-align: center;
      margin-right: 32px;

      &:hover {
        background-color: #004c8a;
        color: #e0f0f6;
      }
    }

    .cancelButton {
      color: $blue;
      border: 2px solid $blue;
      background: $white;
    }
  }
}
