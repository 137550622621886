@import 'src/assets/css/variables';

.content {
  height: 512px;
  width: 1128px;
  min-width: 1128px;
  background: #fff;

  h2 {
    padding: 32px;
    font-size: 18px;
    list-style: 21px;
    color: $dark_grey;
  }

  .form {
    padding: 50px 295px;

    p {
      font-size: 16px;
      font-weight: 700;
      line-height: 18px;
      color: $dark_grey;
      margin-bottom: 18px;
      text-align: center;
    }

    .p_grey {
      font-size: 16px;
      line-height: 18px;
      color: $medium_grey;
      margin-bottom: 30px;
      text-align: center;
    }
  }

  .buttons {
    display: flex;
    position: absolute;
    bottom: 32px;
    right: 32px;

    button {
      min-width: 115px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      outline: none;
      border-radius: 3px;
      font-size: 12px;
      line-height: 14px;
      cursor: pointer;
    }

    .closeBtn {
      background: $white;
      border: 2px solid $blue;
      color: $blue;
    }

    .submitBtn {
      background: $blue;
      border: none;
      color: $white;
      margin-left: 12px;
    }
  }
}

.loadingState {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 100px;
  margin-bottom: 100px;
}
