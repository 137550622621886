.tableview {
  width: 100%;
  table {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 2px 6px #e5e5e5;
    border-spacing: 0px;

    .headerRow {
      height: 50px;
      th {
        border-bottom: 0.5px solid #a3a6b3;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: #323335;
        text-align: start;
        cursor: pointer;
        text-transform: uppercase;
      }
      th:first-child {
        padding-left: 10px;
      }

      .th1 {
        width: 261px;
        padding-left: 10px;
      }

      .th2 {
        width: 175px;
      }
      .th3 {
        width: 200px;
      }
      .th4 {
        width: 125px;
      }
      .th5 {
        width: 150px;
      }
      .th6 {
        width: 100px;
      }
    }
  }
}

.trLarge {
  width: 400px;
}

.emptyContainer {
  height: calc(100vh - 200px);
  max-height: 564px;
  .emptyElement {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  p {
    color: #a3a6b3;
  }
}

.infoTooltip {
  width: 305px;
  height: 148px;
  font-size: 12px;
  line-height: 14px;
  padding: 21px;
  text-transform: none;
  font-weight: 500;
  color: #000000;
  top: -66px;
  left: 40px;
  p {
    margin: 0 0 14px;
  }
}

.complianceIconRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  &.single {
    justify-content: space-around;
  }
  .box {
    padding: 0px 22.5px;
    display: flex;
    flex-direction: column;
    .iconWrapper {
      width: 33px;
      height: 34px;
      background: rgba(196, 196, 196, 0.2);
      border-radius: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 4px;
    }
    .iconTitle {
      font-size: 10px;
      color: #a3a6b3;
      font-weight: 500;
      text-align: center;
    }
  }
}
