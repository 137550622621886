@import 'src/assets/css/variables';
.noDataContainer {
  height: calc(100vh - 168px);
  width: calc(100% - 36px);
  margin: 0px 18px;
  max-height: 564px;
  background: $white;
  display: flex;
  align-items: center;
  flex-direction: column;
  svg {
    margin-top: 140px;
  }
  .error {
    color: $critical;
  }
  p {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: $medium_grey;
    margin-top: 30px;
  }

  &__modal {
    width: calc(100% - 36px);
    margin: 0px 18px;
    max-height: 564px;
    background: $white;
    display: flex;
    align-items: center;
    flex-direction: column;
    svg {
      margin-top: 60px;
    }
    .error {
      color: $critical;
    }
    p {
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: $medium_grey;
      margin-top: 30px;
    }
  }
}
