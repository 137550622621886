@import 'src/assets/css/variables';
@import 'src/assets/css/table-base';

.tableButtons {
  margin: 18px;
  position: relative;
}

.devicesParentContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  margin-top: 30px;
  .devicesSection {
    display: flex;
    flex-direction: column;
    p {
      font-size: 22px;
      font-weight: 600;
      color: #323335;
      margin-left: 18px;
    }
  }
}
.btn {
  display: inline-block;
  height: 24px;
  border-radius: 3px;
  font-size: 12px;
  line-height: 14px;
  cursor: pointer;
  text-align: center;
  line-height: 24px;
}

.btnActive {
  width: 144px;
  margin-right: 12px;
  color: $medium_grey;
}

.btnDischarged {
  width: 124px;
  color: $medium_grey;
}

.btnSelected {
  color: $white;
  background: $medium_grey;
}

.searchInput {
  float: right;
  display: inline-block;
}

.noDevicesTitle {
  font-size: 36px;
  font-weight: 500;
  line-height: 42px;
  margin-bottom: 18px;
  margin-top: 39px;
}

.noDevicesSub {
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 64px;
}

.noDevicesBtn {
  height: 48px;
  width: 147px;
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
  line-height: 48px;
  background-color: $blue;
  color: $white;
}

.spacedModal {
  justify-content: space-between;
}

.assignDeviceModal {
  min-height: 426px;

  &__loading {
    min-height: 481px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header {
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    padding: 32px;
    border-bottom: 0.3px solid $medium_grey;
    display: flex;
    justify-content: flex-start;
  }

  .body {
    margin: 32px;

    .deviceInfo {
      display: flex;
      flex-wrap: wrap;

      div {
        width: 477px;

        span:nth-child(1) {
          width: 161px;
          font-size: 14px;
          line-height: 16px;
          font-weight: 700;
          display: inline-block;
          margin-bottom: 33px;
        }
      }
    }

    .assignSelect {
      margin-top: 32px;

      span {
        display: block;
        margin-bottom: 18px;
      }

      select {
        width: 518px;
        padding-left: 10px;
        height: 40px;
        background: $light_grey;
        border-radius: 3px;
        -o-appearance: none;
        -ms-appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: none;
        background-image: url('../../assets/icons/arrow-down.svg');
        background-repeat: no-repeat, repeat;
        background-position: right 0.7em top 50%, 0 0;

        &:focus {
          outline: none;
        }

        option {
          font-weight: normal;
          font-size: 14px;
          background: $light_grey;
          color: $dark_grey;
          &:hover {
            background: $blue;
            color: $white;
          }
          &:focus {
            background: $blue;
            color: $white;
          }
        }
      }
    }
  }

  .assignBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;

    .textStrong {
      font-size: 16px;
      line-height: 110%;
      font-weight: 700;
    }

    .textSecondary {
      font-size: 16px;
      color: $medium_grey;
      line-height: 115%;
      margin-top: 18px;
      max-width: 600px;
      text-align: center;
    }

    .customSelect {
      margin-top: 16px;
      padding: 10px;
      height: 40px;
      width: 300px;
      background: $light_grey;
      border-radius: 3px;
      background-image: url('../../assets/icons/arrow-down.svg');
      background-repeat: no-repeat, repeat;
      background-position: right 0.7em top 50%, 0 0;
      cursor: pointer;
    }

    .customSelectOptions {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      position: absolute;
      bottom: 30px;
      border-top: 1px solid $medium_grey;

      .customOption {
        width: 300px;
        height: 40px;
        padding: 10px;
        font-size: 14px;
        background: $light_grey;
        color: $dark_grey;
        &:hover {
          background: $blue;
          color: $white;
        }
        &:focus {
          background: $blue;
          color: $white;
        }
      }
    }
  }

  .buttons {
    display: flex;
    position: absolute;
    bottom: 32px;
    right: 32px;

    &__bottomFixed {
      display: flex;
      justify-content: flex-end;
      margin: 149px 32px -5px 32px;

      button {
        min-width: 115px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        outline: none;
        border-radius: 3px;
        font-size: 12px;
        line-height: 14px;
        cursor: pointer;
        background: $white;
        border: 2px solid $blue;
        color: $blue;
      }
    }

    button {
      min-width: 115px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      outline: none;
      border-radius: 3px;
      font-size: 12px;
      line-height: 14px;
      cursor: pointer;
    }

    button:nth-child(1) {
      margin-right: 12px;
    }

    .submitBtn {
      background: $blue;
      border: none;
      color: $white;
      margin-left: 12px;
    }

    .btnDisabled {
      background: $medium_grey;
      border: none;
      color: $white;
      margin-left: 12px;
      cursor: initial;
    }

    .closeBtn {
      background: $white;
      border: 2px solid $blue;
      color: $blue;
    }
  }
}
