@import 'src/assets/css/variables';
@import 'src/assets/css/table-base';
.commandHeader {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
  a {
    padding: 5px 15px;
    min-width: 150px;
    text-decoration: none;
    border: none;
    outline: none;
    background: #417eb9;
    border-radius: 3px;
    color: $white;
    cursor: pointer;
    text-align: center;
    &:hover {
      background-color: #004c8a;
      color: #e0f0f6;
    }
  }
}
