@import 'src/assets/css/variables';
@import 'src/assets/css/table-base';

.header {
  width: 100%;
  height: 60px;
  nav {
    background: transparent;
    width: 100%;
    height: 60px;
    margin: 0;
    padding: 0;
    .navRight {
      padding: 0;
      text-align: center;
      width: 100%;
      .options {
        margin-left: 18px;
        float: right;
        font-size: 12px;
      }
      .dropdownButton {
        cursor: pointer;
        margin: 0;
        border: 1px solid $blue;
        box-sizing: border-box;
        border-radius: 3px;
        padding: 5px 12px;
        align-items: center;
        color: $blue;
        .arrow {
          margin-left: 13px;
        }
      }
    }
  }
}

.button {
  padding: 5px 15px;
  min-width: 150px;
  text-decoration: none;
  border: none;
  outline: none;
  background: $blue;
  border-radius: 3px;
  color: $white;
  cursor: pointer;
  &:hover {
    background-color: #004c8a;
    color: #e0f0f6;
  }
}

.filterDatepicker {
  position: absolute;
  top: 2px;
  right: 0;
  box-shadow: 0 2px 6px $box-shadow-default;
  border-radius: 3px;
}

.floatRight {
  justify-content: flex-end;
}

.upcomingStatus {
  font-size: 12px;
  color: $medium_grey;
}

.pendingStatus {
  font-size: 12px;
  font-weight: 600;
  color: $dark_grey;
}

.repeatIcon {
  margin-right: 4px;
  vertical-align: middle;
}
.tr .td:nth-last-child(1) {
  padding-left: 10px;
  justify-content: flex-start;
}

.tr .td:nth-last-child(2) {
  padding-left: 10px;
  justify-content: flex-start;
}
