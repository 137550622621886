.insightContainer {
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 2px 6px #e5e5e5;
  min-width: 410px;
  width: 410px;
  height: 271px;
  margin-right: 18px;
  position: relative;
  &.critical {
    border: 2px solid #ed6f6a;
  }
  &.stable {
    border: 2px solid #6fcf97;
  }
  &.at_risk {
    border: 2px solid #efbb55;
  }

  .top {
    padding: 18px;
    padding-bottom: 10px;
    .infoTooltip {
      width: 440px;
      height: 60px;
      font-size: 12px;
      line-height: 14px;
      padding: unset;
      .hoverText {
        background: #f5f6fa;
        width: inherit;
        height: inherit;
        padding: 14px 17px 14px 20px;
        color: #444444;
      }
    }
    .panelTitle {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 21px;
      color: #323335;
      text-transform: uppercase;
      margin-right: 6px;
    }
  }
  .middle {
    padding-left: 18px;
    padding-right: 18px;
    border-bottom: 0.3px solid #a3a6b3;
    padding-bottom: 16px;
    height: 150px;
    position: relative;
    .title {
      font-weight: bold;
      font-size: 20px;
      line-height: 23px;
      color: #323335;
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      max-height: 66px;
    }
    .description {
      display: block;
      max-width: 100%;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #323335;
      margin-top: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .readMore {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      color: #417eb9;
      cursor: pointer;
      bottom: 16px;
      display: flex;
      position: absolute;
    }
  }
  .bottom {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    padding-left: 18px;
    padding-right: 18px;
    padding-bottom: 18px;
    height: 72px;
    .left {
      display: flex;
      flex-direction: column;
      .dateSection {
        display: flex;
        flex-direction: column;
        padding-top: 10px;
        .dateLabel {
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 14px;
          color: #323335;
        }
        .dateValue {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          height: 14px;
          color: #323335;
        }
      }
      .actionText {
        display: flex;
        flex-direction: row;
        margin-top: 6px;
        span {
          font-style: normal;
          font-size: 12px;
          line-height: 14px;
          &.actionLabelClear {
            color: #ed6f6a;
            font-weight: bold;
            padding-right: 3px;
          }
          &.actionValueClear {
            color: #ed6f6a;
          }
          &.actionLabelCleared {
            font-weight: bold;
            padding-right: 3px;
          }
        }
      }
    }
    .right {
      button {
        background: #417eb9;
        border-radius: 3px;
        width: 115px;
        height: 24px;
        border: none;
        text-decoration: none;
        outline: none;
        font-size: 12px;
        color: #ffffff;
        cursor: pointer;
        &:hover {
          background-color: #004c8a;
          color: #e0f0f6;
        }
      }
    }
  }

  .noInsightMiddle {
    display: flex;
    flex-direction: column;
    padding: 18px;
    p {
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      margin: 10px 0;
      color: #a3a6b3;
    }
    span {
      font-size: 14px;
      line-height: 16px;
      display: block;
      color: #a3a6b3;
    }
  }
  .noInsightBottom {
    padding-right: 18px;
    padding-bottom: 18px;
    position: absolute;
    bottom: 0;
    right: 0;
    button {
      background: #a3a6b3;
      border-radius: 3px;
      width: 115px;
      height: 24px;
      border: 0;
      font-size: 12px;
      line-height: 14px;
      color: #ffffff;
      cursor: not-allowed;
    }
  }
}
