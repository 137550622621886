@import 'src/assets/css/variables';

.labelCheck {
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  color: #323335;
  width: 215px;
  vertical-align: text-top;

  input[type='checkbox'] {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 7px;
    margin: 0;
    z-index: 1;
    cursor: pointer;
    width: 23px;
    height: 23px;
    border-radius: 3px;
  }

  span {
    position: relative;
    top: 0;
    background: $light-grey;
    display: inline-block;
    vertical-align: bottom;
    margin-right: 18px;
    width: 23px;
    height: 23px;
    border-radius: 3px;
    border: 1px solid $blue;
  }

  input[type='checkbox']:checked + span:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 15px;
    height: 12px;
    background: url('../../../assets/icons/check-mark.svg') no-repeat;
    background-size: 100%;
    border-radius: 3px;
  }
}
