@import 'src/assets/css/variables';

.content {
  max-height: 900px;
  justify-content: space-between;
  flex-direction: column;
  display: flex;

  .header {
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    text-transform: uppercase;
    color: $dark_grey;
    padding: 29px;
    border-bottom: 0.3px solid $medium_grey;
    display: flex;
    justify-content: center;

    &__edit {
      padding: 35px 0 0 32px;
      border-bottom: 0.3px solid $medium_grey;
      height: 104px;
      display: flex;
      flex-direction: column;

      .header_title {
        font-size: 18px;
        font-weight: 600;
        line-height: 18px;
        margin-bottom: 18px;
        text-transform: uppercase;
      }

      .milestoneModalNav {
        height: 32px;

        span {
          margin-right: 24px;
          color: $medium_grey;
          font-size: 14px;
          line-height: 16px;
          cursor: pointer;
          &:hover {
            color: $blue;
            border-bottom: 2px solid $blue;
            padding-bottom: 10px;
          }
        }

        .stepSelected {
          color: $blue;
          border-bottom: 2px solid $blue;
          padding-bottom: 10px;
        }
      }
    }
  }

  .formContainer {
    flex-direction: column;
    height: 720px;
    display: flex;
  }

  .body {
    margin: 32px 32px 18px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    color: $dark_grey;

    .subHeading {
      color: $dark_grey;
    }
  }

  .milestoneContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 18px;

    .parentMilestoneList {
      width: 500px;

      .label {
        font-size: 14px;
        line-height: 16px;
        font-weight: 600;
        text-transform: uppercase;
        color: #323335;
        margin-bottom: 9px;
        display: block;
      }

      .scrollingContainer {
        max-height: 523px;
        height: 523px !important;
        background: $light_grey;
        border: 1px solid $light_grey;
      }

      .droppableZone {
        padding: 9px 0;

        .draggableItem {
          width: 100%;
          height: 84px;
          max-height: 84px;
          padding: 9px 14px;
          border-radius: 3px;

          .milestoneContent {
            background: $white;
            width: 100%;
            height: 100%;
            padding: 20px 15px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            .handle {
              width: 68px;
              padding: 0 20px;
              display: flex;
              height: 100%;

              i {
                width: 100%;
                height: 100%;
                background: grey;
              }
            }

            .milestoneText {
              flex-grow: 1;
              font-size: 14px;
              color: $dark_grey;
            }

            .addCTA {
              width: 35px;
            }
          }
        }
      }
    }
  }
}

.trackVerticalCustom {
  div {
    background-color: $medium_grey !important;
    left: -6px;
  }
}

.bottomButtons {
  display: flex;
  justify-content: space-between;
  margin: 0 32px 32px 32px;
  margin-top: auto;

  button {
    min-width: 115px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    outline: none;
    border-radius: 3px;
    font-size: 12px;
    line-height: 14px;
    cursor: pointer;
  }

  .submitBtn {
    background: $blue;
    border: none;
    color: $white;
    margin-left: auto;

    &:hover {
      background-color: #004c8a;
      color: #e0f0f6;
    }
  }

  .closeBtn {
    background: $white;
    border: 2px solid $blue;
    color: $blue;
  }
}
