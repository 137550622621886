@import 'src/assets/css/variables';

.dashboardContainer {
  width: calc(100% - 312px);
  display: flex;
  margin: auto;
  margin-top: 60px;
  flex-direction: column;
  .topRow {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 30px 0;
    & > button {
      font-family: Roboto, sans-serif;
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      outline: none;
      height: 24px;
      padding: 5px 20px;
      border: 0;
      margin-right: 14px;
      &.active {
        color: #ffffff;
        background-color: #a3a6b3;
        border-radius: 3px;
      }

      &.inactive {
        color: #a3a6b3;
        background-color: #f5f6fa;
        &:hover {
          background: #a3a6b3;
          border-radius: 3px;
          color: #fff;
        }
      }
    }
  }
}

.clinicDetails {
  display: flex;
  flex-direction: column;
  height: 229px;
  background-color: $white;
  width: 100%;
  box-shadow: 0px 2px 6px $box-shadow-default;

  .topSection {
    display: flex;
    justify-content: space-between;

    .header {
      margin: 32px;
      font-size: 18px;
      line-height: 21px;
    }

    .edit {
      margin: 18px;
      font-size: 14px;
      color: $blue;
      line-height: 16px;
      cursor: pointer;
    }
  }

  .body {
    display: flex;
    justify-content: center;

    .location {
      display: flex;
      align-items: center;
      margin-right: 40px;
      position: relative;
      bottom: 30px;

      img {
        margin-right: 27px;
        padding-bottom: 20px;
      }

      .description {
        display: flex;
        flex-direction: column;

        span {
          font-size: 14px;
          line-height: 16px;
        }

        .name {
          margin-bottom: 10px;
          font-size: 16px;
          line-height: 19px;
        }

        .phone {
          margin-bottom: 10px;
        }

        .address {
          color: $blue;
          display: flex;
          flex-direction: column;
          cursor: pointer;
          margin-bottom: 10px;
          font-size: 16px;
          line-height: 19px;
          span {
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 5px;
          }
        }
      }
    }

    .contact {
      display: flex;
      align-items: center;
      position: relative;
      bottom: 30px;

      img {
        height: 60px;
        width: 35px;
        margin-right: 27px;
        margin-bottom: 20px;
      }

      .description {
        display: flex;
        flex-direction: column;

        span {
          font-size: 16px;
          line-height: 19px;
          margin-bottom: 8px;
        }
      }
    }
  }
}

.form {
  height: 600px;

  .header {
    width: 100%;
    font-size: 18px;
    line-height: 21px;
    font-weight: 700;
    border-bottom: 0.3px solid $medium_grey;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 32px 0px;
  }

  .body {
    margin: 32px;
    display: flex;
    flex-wrap: wrap;

    > div {
      margin-right: 12px;
    }

    > div:last-child {
      margin-bottom: 0px;
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    margin: 32px;

    button {
      min-width: 115px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      outline: none;
      border-radius: 3px;
      font-size: 12px;
      line-height: 14px;
      cursor: pointer;
    }

    .cancelBtn {
      background: $white;
      border: 2px solid $blue;
      color: $blue;
      margin-right: 12px;
    }

    .saveBtn {
      background: $blue;
      border: none;
      color: $white;
    }
  }
}

.loadingState {
  margin-top: 20%;
}

.clinicReports {
  display: flex;
  width: 100%;
  > div {
    background-color: $white;
    margin-top: 25px;
    box-shadow: 0px 2px 6px $box-shadow-default;
  }
}
