.noteContainer {
  display: flex;
  flex-direction: column;
  .header {
    height: 76px;
    width: 100%;
    padding: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.3px solid #a3a6b3;
    .title {
      text-transform: uppercase;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: #323335;
    }
    svg {
      cursor: not-allowed;
    }
  }
  .top {
    height: 45px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    display: flex;
    padding: 18px 32px;
    border-bottom: 0.3px solid #a3a6b3;
    span {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #323335;
    }
  }
  .middle {
    padding: 32px;
    height: 600px;
    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: #323335;
      margin-bottom: 16px;
    }
    .content {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #323335;
      height: 100%;
      padding-bottom: 32px;

      textarea {
        width: 100%;
        height: 100%;
        min-height: 250px;
        padding: 18px;
        background: #f5f6fa;
        border-radius: 3px;
        border-color: #f5f6fa;
        outline: none;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 110.69%;
        color: #323335;
        font-family: Roboto;
      }
    }
  }
  .footer {
    height: 76px;
    width: 100%;
    padding: 32px;
    display: flex;
    align-items: center;
    border-top: 0.3px solid #a3a6b3;
    justify-content: flex-end;

    span {
      font-family: Roboto;
      font-style: normal;
      font-size: 14px;
      line-height: 16px;
      color: #a3a6b3;
      margin-right: 45px;
    }

    button {
      background: #ffffff;
      border: 2px solid #417eb9;
      box-sizing: border-box;
      border-radius: 3px;
      width: 115px;
      min-width: 115px;
      height: 24px;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: #417eb9;
      cursor: pointer;
      &.submitBtn {
        background-color: #417eb9;
        color: white;
        margin-left: 10px;
      }
    }
  }
}

.filterDatepicker {
  position: absolute;
  top: 2px;
  right: 0;
  box-shadow: 0 2px 6px #e5e5e5;
  border-radius: 3px;
  .rdrDefinedRangesWrapper div {
    background: #2f2a30 !important;
  }
}
.dropdownButton {
  cursor: pointer;
  border: 1px solid #417eb9;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 5px 12px;
  align-items: center;
  color: #417eb9;
  .arrow {
    margin-left: 13px;
  }
}

.loadingContent {
  padding-top: 100px;
}
