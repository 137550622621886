.scoreBar {
  width: 271px;
  height: 6px;
  background: #ffffff;
  box-shadow: 0 2px 6px #e5e5e5;
  display: flex;
  border-radius: 100px;

  .colorBar {
    height: 100%;
    display: flex;
    border-right: 1px solid #f5f6fa;

    &:first-child {
      border-radius: 100px 0 0 100px;
    }

    &:last-child {
      border: unset;
      border-radius: 0 10px 10px 0;
    }
  }
}

.scoreRow {
  width: 271px;
  display: flex;
  flex-direction: row;
  margin-bottom: 6px;

  .score {
    font-size: 11px;
    line-height: 13px;
    display: flex;
    justify-content: flex-end;
    color: #323335;
  }
}
