.infoTooltip {
  width: 500px;
  font-size: 12px;
  line-height: 14px;
  background: #f5f6fa;
  color: #444444;
  padding: 20px 16px 18px 22px;
  top: -140px;
  z-index: 99999;
  text-align: left;

  .container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-wrap: nowrap;
    .row {
      align-items: center;
      display: flex;
      justify-content: space-around;
      .item {
        display: flex;
        align-items: center;
        width: 200px;
        &.big {
          width: 430px;
        }
        figure {
          margin-right: 18px;
          svg {
            height: 40px;
            width: 40px;
          }
        }
      }
    }
  }

  .smallBox {
    position: relative;
    background: #ffff;
    clip-path: polygon(3% 0, 100% 0%, 100% 100%, 3% 100%, 0% 50%);
    z-index: 9999;
    filter: drop-shadow(0 5px 10px #a3a6b3);
    height: 35px;
    left: 13px;
    top: -30px;
    padding: 10px 10px 10px 15px;
    p {
      line-height: 17px;
      align-items: center;
      color: #323335;
      margin: 0;
    }
  }
}
