.row {
    flex-direction: row;
    width: 100%;
    padding-right: 10px;
    justify-content: left;
    display: flex;
    min-height: 50px;
    align-items: center;
}

.pentitle {
    font-size: 16px;
}

.penQuestionContainer {
    margin-top: 40px;

    .penQuestionText {

        font-size: 16px;
    }

    .penQuestionAnswersContainer {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        margin-left: 40px;

        & span {
            font-size: 16px;
            position: relative;
            & span.selectedAnswer {
                margin-left: -2px;
                border-radius: 50%;
                width: 25px;
                height: 25px;
                background: #fff;
                border: 2px solid red;
            }
        }
    }
}

.totalScoreContainer {
    margin-top: 40px;
    span {
        font-size: 16px;
    }
}